export const ALPHABETIC_ONLY_REGEX = /^[A-Za-z]+$/;
export const ALPHABETIC_WITH_SPACE_REGEX = /^[a-zA-Z ]*$/;
export const ALPHANUMERICAL_WITH_SPECIAL_CHARACTER_REGEX = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_]).{3}/;
export const NUMERICAL_WITH_PLUS_REGEX = /^\+?\d+$/;
export const NUMERICAL_WITH_DASH_REGEX = /[\d -]+$/;
export const NUMERICAL_REGEX = /^\d+$/;
export const INPUT_THOUSAND_SEPARATOR_REGEX = /\B(?=(\d{3})+(?!\d))/g;
export const ONLY_NUMERICAL_REGEX = /[^0-9]/g;
export const NAME_WITH_SYMBOL_REGEX = /^[a-zA-Z .,'-]*$/;
export const NPWP_REGEX = /^[0-9]{2}.[0-9]{3}.[0-9]{3}.[0-9]{1}-[0-9]{3}.[0-9]{3}$/;
export const SEPARATE_NPWP_REGEX = /(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/;
export const CAPITAL_ONLY_REGEX = /(?=[A-Z])/;
export const CURRENCY_SYMBOLS_REGEX = /[,.]/;
