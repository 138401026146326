import {
  SEPARATE_NPWP_REGEX,
  CAPITAL_ONLY_REGEX,
  CURRENCY_SYMBOLS_REGEX,
} from 'modules/constants/regex.const';

export const normalizeString = string =>
  string
    .split(' ')
    .filter(val => val)
    .join(' ');

export const toCamelCase = string => {
  const convertToCamelCase = string
    .toLowerCase()
    .replace(/(?:(^.)|([-_\s]+.))/g, match => {
      return match.charAt(match.length - 1).toUpperCase();
    });

  return convertToCamelCase;
};

export const removeSeparator = (value = '') => {
  const nominalNonSeparator = value.split(CURRENCY_SYMBOLS_REGEX).join('');
  return nominalNonSeparator;
};

export const removeNonNumberCharacters = (string = '') => {
  const nonNumberString = string.replace(/\D/g, '');
  return nonNumberString;
};

export const npwpFormated = (value = '') => {
  const convertFormat = value.replace(SEPARATE_NPWP_REGEX, '$1.$2.$3.$4-$5.$6');

  return convertFormat;
};

export const removeFirstCapitalLetter = string =>
  string
    .split(CAPITAL_ONLY_REGEX)
    .slice(1)
    .join('');

export const addZeroPadding = string => {
  if (string.length < 4) {
    const paddingZeros = 4 - string.length;
    const paddedString = '0'.repeat(paddingZeros) + string;

    return paddedString;
  }
  return string;
};
