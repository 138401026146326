import { isFunction } from './type.util';

export const basicReducer = (prevState, newState) => {
  if (isFunction(newState)) {
    const stateHandler = newState;
    return stateHandler(prevState);
  }
  const isObject = obj =>
    obj.constructor === Object && typeof obj === 'object' && obj !== null;
  const isStateTypeObject = isObject(prevState) && isObject(newState);
  const isStateTypeArray = Array.isArray(prevState) && Array.isArray(newState);
  if (!isStateTypeObject && !isStateTypeArray) {
    return new Error('type of state is not allowed');
  }
  if (!isStateTypeObject) {
    return [...prevState, ...newState];
  }
  return { ...prevState, ...newState };
};
