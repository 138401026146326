import { API } from 'modules/env';

const { MINIMAL_SAVING_AMOUNT } = API;

export const BRANDING_NAME = 'Amar Bank Deposito';
export const SENYUMKU_LITE = 'senyumku_lite';
export const MIN_SAVING = MINIMAL_SAVING_AMOUNT;
export const MAX_SAVING = 99000000000;
export const PRODUCT_MAX = 'max';
export const PRODUCT_FLEX = 'flex';
export const PRODUCT_DEPOSITO = 'deposito';
export const MIN_TRANSFER_OUT = 10000;
export const MAX_TRANSFER_OUT = 200000000;
export const INTEREST_FLEX = 5;
export const INTEREST_DEPOSITO = 8;
export const INTEREST_BEFORE_PROMO = '8%';
export const TRANSFER_FEE = 2900;
export const TUNAIKU_LINK = 'http://tunaiku.com';
export const INTEREST = {
  maxMin: '7,5%',
  maxMedium: '8%',
  maxMaximum: '9%',
};

export const DEFAULT_VALUE = {
  amount: '1000000',
  period: 12,
};

export const PRODUCT_CODE = '2';

export const PORTFOLIO_PRODUCT_DEPOSIT_STATUS = {
  active: 1,
  inactive: 0,
};

export const PRODUCT = {
  deposit: 'deposit',
  senyumku: 'senyumku',
};

export const PROMO_ACTIVE = false;

export const DAYS_IN_A_YEAR = 365;
export const SPECIALLY_ALLOWED_TOP_UP_NOMINAL = 1;
export const ENV_DEVELOPMENT = 'development';
export const MAXIMUM_TOTAL_BALANCE_WITH_NORMAL_INTEREST = 1000000000;
