import React from 'react';
import useAccordionGroup from './accordion-group.hook';

import { isFunction } from 'modules/utilities/type.util';

export const Accordion = props => {
  const { tag: Tag = 'div', render, defaultActiveIndex, ...attributes } = props;

  const { onAccordionChanged, activeAccordion } = useAccordionGroup(
    defaultActiveIndex,
  );

  const isRenderFunction = isFunction(render);

  return (
    <Tag {...attributes}>
      {isRenderFunction
        ? render({ activeAccordion, onAccordionChanged })
        : null}
    </Tag>
  );
};
