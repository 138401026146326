import React from 'react';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from 'modules/components/accordion-group';
import { ArrowToggle } from 'modules/components/arrow-toggle';
import { getSortedList } from 'modules/utilities/sorted-list.util';

import './faq.scss';

const FaqComponent = props => {
  const { children, className, faqList } = props;
  const sortedFaqList = getSortedList(faqList);

  const classWrapper = classNames(
    'FaqComponent',
    'Card',
    'Card--borderless',
    'BgColor-white',
    className,
  );

  return (
    <div className={classWrapper}>
      {children}
      <div className="Card-body PaddingBottom-0 PaddingTop-0 BgColor-white">
        <Accordion
          render={({ onAccordionChanged, activeAccordion }) => (
            <>
              {sortedFaqList.map((faq, index) => (
                <div className="BorderTop-grey20">
                  <AccordionHeader
                    id={`Accr${index + 1}`}
                    onHeaderClick={onAccordionChanged(index)}>
                    <div className="Grids">
                      <div className="Grid Grid-11 PaddingRight-0">
                        <div className="FaqComponent-question FontSize-small MarginBottom MarginTop">
                          <ReactMarkdown
                            source={faq.question}
                            escapeHtml={false}
                          />
                        </div>
                      </div>
                      <div className="Grid Grid-1 Flex JustifyContent-end AlignItems-center PaddingLeft-0">
                        <ArrowToggle
                          isCollapsed={activeAccordion === index}
                          theme="green80"
                        />
                      </div>
                    </div>
                  </AccordionHeader>

                  <AccordionBody isOpen={activeAccordion === index}>
                    <div className="FaqComponent-answer Color-grey90 FontSize-xsmall MarginBottom Hyperlink-green80">
                      <ReactMarkdown source={faq.answer} escapeHtml={false} />
                    </div>
                  </AccordionBody>
                </div>
              ))}
            </>
          )}
        />
      </div>
    </div>
  );
};

export default FaqComponent;
