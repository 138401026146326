export const PRODUCT_DATA = {
  deposito: {
    name: 'Deposito',
    description:
      'Investasi rendah resiko dengan keuntungan yang lebih tinggi dari tabungan biasa',
    promo: false,
    extraInfo: [
      `Bunga dihitung bulanan dan dapat ditarik \n <b>pada akhir periode</b>`,
    ],
  },
};
