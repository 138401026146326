import { useEffect, useState } from 'react';

const isServerSide = () => typeof window === 'undefined';

const getPageDataFromJson = () => {
  if (isServerSide()) {
    return null;
  }

  const scriptElement = document.getElementById('__PAGE_DATA__');
  return !!scriptElement && JSON.parse(scriptElement.textContent);
};

const removePageDataJson = () => {
  if (isServerSide()) {
    return;
  }

  const scriptElement = document.getElementById('__PAGE_DATA__');
  if (scriptElement) {
    scriptElement.remove();
  }
};

const initializePageData = (staticContext, initialPageData) => {
  if (staticContext?.pageData) {
    return staticContext.pageData;
  }

  const hydrationData = getPageDataFromJson();
  if (hydrationData) {
    return hydrationData;
  }

  return initialPageData;
};

const usePageData = (props, { initialPageData, getPageData }) => {
  const { staticContext } = props;
  const [pageData, setPageData] = useState(() =>
    initializePageData(staticContext, initialPageData),
  );
  const [isLoadingPageData, setIsLoadingPageData] = useState(false);

  const fetchClientData = async () => {
    try {
      setIsLoadingPageData(true);
      const clientData = await getPageData();
      setPageData(clientData);
    } catch {
    } finally {
      setIsLoadingPageData(false);
    }
  };

  useEffect(() => {
    const hydrationData = getPageDataFromJson();

    if (hydrationData) {
      removePageDataJson();
    } else {
      fetchClientData();
    }
  }, [getPageData]);

  return { pageData, isLoadingPageData };
};

export default usePageData;
