import { useState, useEffect } from 'react';

import { decodeToken } from 'modules/utilities/encryption.util';
import { useOAuth } from '@tunaiku.npm/react-library/dist/cjs/modules/oauth';
import useStorageManager from 'modules/services/storage-manager.hook';
import { LOCAL_STORAGE } from 'modules/constants/local-storage.const';

const useUserInformation = () => {
  const { token } = useOAuth();
  const [dataCustomer, setDataCustomer] = useState({});
  const { getEncryptedDataFromLocalStorage } = useStorageManager();

  useEffect(() => {
    getDataCustomer();
  }, []);

  const getTokenDecode = () => decodeToken(token);

  const getUsernameAsPhoneNumber = () =>
    getTokenDecode() && getTokenDecode().preferred_username;

  const getDataCustomer = () => {
    const isVerifying = getEncryptedDataFromLocalStorage(
      LOCAL_STORAGE.customer,
    );

    setDataCustomer(isVerifying);
  };

  return {
    getUsernameAsPhoneNumber,
    dataCustomer,
  };
};

export default useUserInformation;
