import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import './banner.scss';

const Banner = props => {
  const {
    icon,
    title,
    background,
    link,
    id,
    className,
    children,
    iconSize,
  } = props;
  const history = useHistory();

  const style = {
    backgroundImage: `url(${background})`,
  };

  const cssClasses = {
    Banner: classNames(`Banner`, className),
    BannerIcon: classNames(
      `Banner-icon`,
      iconSize && `MarginRight-xsmall MarginTop-xsmall`,
    ),
  };
  return (
    <div className={cssClasses.Banner} style={style}>
      <div className="Container">
        <div className="Flex">
          {icon && (
            <img
              id={id}
              src={icon}
              alt="icon arrow"
              width={iconSize}
              height={iconSize}
              className={cssClasses.BannerIcon}
              onClick={() => history.push(link)}
            />
          )}
          <h5 className="Banner-title Typography-heading24 MarginLeft-small PaddingTop-3xsmall">
            {title}
          </h5>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Banner;
