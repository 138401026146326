import { useEffect, useReducer, useContext } from 'react';
import { useApiInvoker } from '@tunaiku.npm/react-library/dist/cjs/modules/api-invoker';
import { SnackbarContext } from 'modules/components/snackbar';
import { basicReducer } from 'modules/utilities/state.util';

import { PERIOD_LIST_ENDPOINT } from 'pages/modules/constants/endpoint.const';
import VALIDATION_MESSAGES from 'assets/json/validation-messages.json';

const usePeriodList = (isNominalAboveOneBillion, periodDeposit) => {
  const { apiInvoker, getErrorStatus } = useApiInvoker({});
  const defaultValues = {
    interestRate: '',
    periodInterestList: [],
    isErrorGetPeriodList: false,
  };
  const [state, setState] = useReducer(basicReducer, defaultValues);
  const { openSnackbar } = useContext(SnackbarContext);
  const { interestRate, periodInterestList, isErrorGetPeriodList } = state;

  useEffect(() => {
    fetchPeriodList();
  }, [isNominalAboveOneBillion]);

  const fetchPeriodList = () =>
    apiInvoker
      .get(PERIOD_LIST_ENDPOINT)
      .then(handleGetPeriodListSuccess)
      .catch(handleGetPeriodListFailed);

  const handleGetPeriodListSuccess = res => {
    const periodList = res.data;
    setState({ periodInterestList: periodList, isErrorGetPeriodList: false });
    handleInterestRate(periodList);
  };

  const handleGetPeriodListFailed = err => {
    const errorStatus = getErrorStatus(err);
    const { isServerError } = errorStatus;

    if (isServerError) {
      openSnackbar({
        value: VALIDATION_MESSAGES.GLOBAL_ERROR,
      });
      setState({ isErrorGetPeriodList: true });
    }
  };

  const handleInterestRate = periodList => {
    const defaultInterestRate = periodList.find(
      list => list.period === periodDeposit,
    );

    if (defaultInterestRate) {
      setState({
        interestRate: isNominalAboveOneBillion
          ? defaultInterestRate.interestRateAfterValidation
          : defaultInterestRate.interestRate,
      });
    }
  };

  const interestRateList = periodInterestList.map(list => list.interestRate);

  const maxInterestRate =
    interestRateList.length > 0 ? Math.max(...interestRateList) : '';

  const minInterestRate =
    interestRateList.length > 0 ? Math.min(...interestRateList) : '';

  return {
    periodInterestList,
    interestRate,
    maxInterestRate,
    minInterestRate,
    isErrorGetPeriodList,
  };
};
export default usePeriodList;
