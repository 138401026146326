import React from 'react';

export const AccordionHeader = props => {
  const { children, onHeaderClick, className, ...attributes } = props;

  return (
    <div className={className} onClick={onHeaderClick} {...attributes}>
      {children}
    </div>
  );
};
