export const encodeData = data => {
  const encode = btoa(data);
  return encode;
};

export const decodeData = data => {
  const decode = atob(data);
  return decode;
};

export const decodeToken = str => {
  if (!str) return null;

  str = str.split('.')[1];
  str = str.replace('/-/g', '+');
  str = str.replace('/_/g', '/');

  switch (str.length % 4) {
    case 0:
      break;
    case 2:
      str += '==';
      break;
    case 3:
      str += '=';
      break;
    default:
      throw new Error('Invalid token');
  }

  str = (str + '===').slice(0, str.length + (str.length % 4));
  str = str.replace(/-/g, '+').replace(/_/g, '/');
  str = decodeURIComponent(escape(atob(str)));
  str = JSON.parse(str);
  return str;
};
