import { useState } from 'react';

const useAccordionGroup = defaultActiveIndex => {
  const [activeAccordion, setActiveAccordion] = useState(defaultActiveIndex);

  const onAccordionChanged = index => () =>
    setActiveAccordion(prevActiveAccordion =>
      prevActiveAccordion === index ? -1 : index,
    );

  return {
    onAccordionChanged,
    activeAccordion,
  };
};

export default useAccordionGroup;
