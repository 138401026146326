import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'modules/constants/routes.const';

const SEO = ({ title, meta, keywords }) => {
  const location = useLocation();
  const isInsideLanding = location.pathname === ROUTES.home;

  const description =
    'Deposito online berjangka yang aman dan menguntungkan. Suku bunga lebih tinggi dari bank biasa, tersedia kalkulator, simulasi | Deposito Senyumku';
  const author = 'Deposito Online dengan Bunga Tertinggi | Senyumku';
  const metaList = [
    {
      name: `description`,
      content: description,
    },
    {
      name: `google-site-verification`,
      content: `k9ayt7s-AvlC2O3sPAk0iZmHfNFWjh8bnq9yYTjSUvI`,
    },
    {
      property: `og:title`,
      content: 'Deposito Online dengan Bunga Tertinggi | Senyumku',
    },
    {
      property: `og:description`,
      content: description,
    },
    {
      property: `og:type`,
      content: `article`,
    },
    {
      property: `og:site_name`,
      content: `Amar Bank Deposito Online`,
    },
    {
      property: `og:url`,
      content: 'https://deposito-retail.amarbank.co.id',
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: description,
    },
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={title}
      meta={metaList
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : [],
        )
        .concat(meta)}>
      <title>
        {isInsideLanding ? author : `Amar Bank Deposito | ${title}`}
      </title>
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
};

export default SEO;
