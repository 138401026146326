export const separatorThousand = value => {
  let separatedNumber;
  if (value !== '' && value !== null) {
    separatedNumber = Number(value).toLocaleString(['ban', 'id']);
  }
  return separatedNumber;
};

export const formatedCurrencyValue = value => {
  return value ? separatorThousand(value) : '0';
};
