import React from 'react';
import classNames from 'classnames';
import { DEFAULT_ICON_SIZE } from './arrow-toggle.const';
import { ReactComponent as ChevronDown } from 'assets/images/chevron-down-solid.svg';
import { ReactComponent as ChevronUp } from 'assets/images/chevron-up-solid.svg';

const ArrowToggle = props => {
  const { isCollapsed, theme, size, className } = props;

  const classes = classNames(
    {
      [`Color-${theme}`]: !!theme,
    },
    className,
  );

  const getSizeIcon = size ? size : DEFAULT_ICON_SIZE;

  return isCollapsed ? (
    <ChevronUp className={classes} width={getSizeIcon} />
  ) : (
    <ChevronDown className={classes} width={getSizeIcon} />
  );
};

export default ArrowToggle;
