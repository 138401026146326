import React from 'react';
import classNames from 'classnames';
import './separator.scss';

const defaultProps = {
  tag: 'div',
};

const Separator = props => {
  // prettier-ignore
  const {
    tag: Tag,
    text,
    className,
    ...attributes
  } = props;

  const classes = classNames(
    `Separator`,
    text && `Separator--withText`,
    className,
  );

  return (
    <Tag {...attributes} className={classes}>
      {text}
    </Tag>
  );
};

Separator.defaultProps = defaultProps;

export default Separator;
