import React from 'react';
import ReactMarkdown from 'react-markdown';
import usePeriodList from 'pages/modules/services/period-list.hook';

import { PRODUCT_DATA } from 'modules/constants/product-detail.const';
import { Separator } from 'modules/components/separator';
import { ReactComponent as InterestIcon } from 'assets/images/interest-icon.svg';
import { ReactComponent as IconMoney } from 'assets/images/icon-money.svg';
import { ReactComponent as IconCalender } from 'assets/images/icon-calendar-event.svg';

const Content = ({ type }) => {
  const { maxInterestRate } = usePeriodList();

  return (
    <>
      <h5 className="Typography-heading24 Color-green80">
        {PRODUCT_DATA[type].name}
      </h5>

      <p className="FontSize-small MarginTop MarginBottom">
        {PRODUCT_DATA[type].description}
      </p>

      <div class="Flex AlignItems-center">
        <div>
          <InterestIcon className="MarginRight-small" />
          Bunga tinggi hingga
        </div>
        <div class="FontSize-medium FontWeight-bold Color-green80 MarginLeft-xsmall MarginRight-xsmall">
          {`${maxInterestRate}%`}
        </div>
        per tahun
      </div>

      <div class="Flex AlignItems-center MarginTop-small MarginBottom-small">
        <div>
          <IconMoney className="MarginRight-small" />
          Minimal deposito hanya Rp
        </div>
        <div class="FontSize-medium FontWeight-bold Color-green80 MarginLeft-xsmall MarginRight-xsmall">
          100.000
        </div>
      </div>

      <div class="Flex AlignItems-center">
        <IconCalender className="MarginRight-small" />
        Periode deposito dari 1 sampai 36 bulan
      </div>

      <Separator />

      <ul className="PaddingLeft ListStyle-disc">
        {PRODUCT_DATA[type].extraInfo.map(info => (
          <li>
            <ReactMarkdown source={info} escapeHtml={false} />
          </li>
        ))}
      </ul>
    </>
  );
};

export default Content;
